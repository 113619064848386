<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="accent text-h5 font-weight-bold pa-3">
        <v-icon color="primary" left>mdi-file-chart-outline</v-icon>
        Relatórios
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <UsersReport />

        <UsersAClassificarAli />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Relatorios",

  components: {
    UsersReport: () => import("./users/Users.vue"),
    UsersAClassificarAli: () => import("./UsersAClassificarAli.vue"),
  },
};
</script>

<style></style>
